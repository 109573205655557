var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-post","cancel-variant":"outline-secondary","ok-title":_vm.$t('Update'),"cancel-title":_vm.$t('Close'),"ok-variant":"primary","centered":"","no-enforce-focus":"","modal-class":"modal-primary","title":_vm.$t('Update post')},on:{"ok":_vm.confirm,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"updatePostModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"title"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Title")))]),_c('validation-provider',{attrs:{"name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.post.title),callback:function ($$v) {_vm.$set(_vm.post, "title", $$v)},expression:"post.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"title_en"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("English Title")))]),_c('validation-provider',{attrs:{"name":"title_en"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title_en","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.post.title_en),callback:function ($$v) {_vm.$set(_vm.post, "title_en", $$v)},expression:"post.title_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"category"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Category")))]),_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.postCategories,"reduce":function (val) { return val.id; },"clearable":false,"placeholder":"Select category","label":"name","input-id":"category"},model:{value:(_vm.post.category_id),callback:function ($$v) {_vm.$set(_vm.post, "category_id", $$v)},expression:"post.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"description"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Description")))]),_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.post.short_description),callback:function ($$v) {_vm.$set(_vm.post, "short_description", $$v)},expression:"post.short_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"description_en"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("English Description")))]),_c('validation-provider',{attrs:{"name":"description_en"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description_en","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.post.short_description_en),callback:function ($$v) {_vm.$set(_vm.post, "short_description_en", $$v)},expression:"post.short_description_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"content"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Content")))]),_c('validation-provider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":"Vietnamese","active":""}},[_c('p',[_c('Editor',{attrs:{"api-key":_vm.API_KEY,"init":_vm.init,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.post.content),callback:function ($$v) {_vm.$set(_vm.post, "content", $$v)},expression:"post.content"}})],1)]),_c('b-tab',{attrs:{"title":"English"}},[_c('p',[_c('Editor',{staticClass:"h-100",attrs:{"api-key":_vm.API_KEY,"init":_vm.init,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.post.content_en),callback:function ($$v) {_vm.$set(_vm.post, "content_en", $$v)},expression:"post.content_en"}})],1)])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"image"}},[_c('label',{attrs:{"for":""}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Image"))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"image","rules":"size:10240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"input-id":"image","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.post.image),callback:function ($$v) {_vm.$set(_vm.post, "image", $$v)},expression:"post.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-img',{staticClass:"mr-2 rounded",staticStyle:{"width":"150px"},attrs:{"src":_vm.post.showImg,"fluid":""}})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.post.image),expression:"post.image"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.post.image = null}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1),_c('b-form-group',{attrs:{"label-for":"link"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Link")))]),_c('validation-provider',{attrs:{"name":"link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"link","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.post.link),callback:function ($$v) {_vm.$set(_vm.post, "link", $$v)},expression:"post.link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v("Status")]),_c('b-form-radio',{attrs:{"name":"status","value":"1","inline":""},model:{value:(_vm.post.status),callback:function ($$v) {_vm.$set(_vm.post, "status", $$v)},expression:"post.status"}},[_vm._v(" Active ")]),_c('b-form-radio',{attrs:{"name":"status","value":"2","inline":""},model:{value:(_vm.post.status),callback:function ($$v) {_vm.$set(_vm.post, "status", $$v)},expression:"post.status"}},[_vm._v(" Inactive ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
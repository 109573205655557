<template>
    <div>
        <b-modal
            id="modal-update-post"
            cancel-variant="outline-secondary"
            :ok-title="$t('Update')"
            :cancel-title="$t('Close')"
            ok-variant="primary"
            centered
            no-enforce-focus
            modal-class="modal-primary"
            :title="$t('Update post')"
            @ok="confirm"
            @hidden="resetModal()"
        >
            <!-- form -->
            <validation-observer ref="updatePostModal">
                <b-form method="POST" @submit.prevent="validationForm">
                    <b-form-group label-for="title">
                        <label class="mb-1">{{ $t("Title") }}</label>
                        <validation-provider #default="{ errors }" name="title">
                            <b-form-input
                                id="title"
                                v-model="post.title"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="title_en">
                        <label class="mb-1">{{ $t("English Title") }}</label>
                        <validation-provider #default="{ errors }" name="title_en">
                            <b-form-input
                                id="title_en"
                                v-model="post.title_en"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="category">
                        <label class="mb-1">{{ $t("Category") }}</label>
                        <validation-provider
                            #default="{ errors }"
                            name="category"
                            rules="required"
                        >
                            <v-select
                                v-model="post.category_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="postCategories"
                                :reduce="(val) => val.id"
                                :clearable="false"
                                placeholder="Select category"
                                label="name"
                                input-id="category"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="description">
                        <label class="mb-1">{{ $t("Description") }}</label>
                        <validation-provider #default="{ errors }" name="description">
                            <b-form-textarea
                                id="description"
                                v-model="post.short_description"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="description_en">
                        <label class="mb-1">{{ $t("English Description") }}</label>
                        <validation-provider #default="{ errors }" name="description_en">
                            <b-form-textarea
                                id="description_en"
                                v-model="post.short_description_en"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="content">
                        <label class="mb-1"
                        ><span class="text-danger">*</span> {{ $t("Content") }}</label
                        >
                        <validation-provider
                            #default="{ errors }"
                            name="content"
                            rules="required"
                        >
                            <b-tabs content-class="mt-3">
                                <b-tab title="Vietnamese" active
                                ><p>
                                    <Editor
                                        :api-key=API_KEY
                                        :init="init"
                                        v-model="post.content"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    /></p
                                ></b-tab>
                                <b-tab title="English"
                                ><p>
                                    <Editor
                                        :api-key=API_KEY
                                        :init="init"
                                        class="h-100"
                                        v-model="post.content_en"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    /></p
                                ></b-tab>
                            </b-tabs>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group label-for="image">
                        <label for=""
                        ><span class="text-danger">*</span> {{ $t("Image") }}
                            <span class="text-secondary">(Không > 10Mb)</span></label
                        >
                        <validation-provider
                            #default="{ errors }"
                            name="image"
                            rules="size:10240"
                        >
                            <b-form-file
                                v-model="post.image"
                                input-id="image"
                                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group>
                        <b-img
                            :src="post.showImg"
                            fluid
                            style="width: 150px"
                            class="mr-2 rounded"
                        />
                    </b-form-group>
                    <b-button
                        v-show="post.image"
                        variant="outline-danger"
                        class="btn-sm btn-icon"
                        @click="post.image = null"
                    >
                        <feather-icon icon="XIcon" />
                    </b-button>
                    <b-form-group label-for="link">
                        <label class="mb-1">{{ $t("Link") }}</label>
                        <validation-provider #default="{ errors }" name="link">
                            <b-form-input
                                id="link"
                                v-model="post.link"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <!-- Status -->
                    <b-form-group label-for="status">
                        <label class="mb-1 d-block">Status</label>
                        <b-form-radio v-model="post.status" name="status" value="1" inline>
                            Active
                        </b-form-radio>
                        <b-form-radio v-model="post.status" name="status" value="2" inline>
                            Inactive
                        </b-form-radio>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
    BLink,
    BImg,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    VBModal,
    BInputGroupAppend,
    BFormTextarea,
    BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import baseUrl from "@/libs/base-url";
import Editor from '@tinymce/tinymce-vue'
import API_KEY from "@/auth/apikey/apikeyeditor";
export default {
    components: {
        BLink,
        BImg,
        BRow,
        BCol,
        BTabs,
        BTab,
        BForm,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BButton,
        BInputGroup,
        BInputGroupPrepend,
        BModal,
        BFormTextarea,
        BBadge,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        Editor
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {
        postCategories: {
            type: Array,
            default: null,
        },
        postDetail: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            API_KEY,
            post: {
                title: "",
                title_en: "",
                category_id: "",
                image: "",
                showImg: null,
                short_description: "",
                short_description_en: "",
                link: "",
                status: "",
                content: "",
                content_en: "",
            },
            init:{
                height: 600,
                content_style: this.$store.state.appConfig.layout.skin === "dark" ? "body { color: white; background-color:#293046 }" : "",
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'wordcount'
                ],
                toolbar: ' undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent inden |image',
                skin: this.$store.state.appConfig.layout.skin === "dark" ? 'oxide-dark' : '',
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.onchange = function () {
                    var file = this.files[0];

                    var reader = new FileReader();
                    reader.onload = function () {
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                        var base64 = reader.result.split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), { title: file.name });
                    };
                        reader.readAsDataURL(file);
                    };

                    input.click();


                },

            }


        };
    },
    watch: {
        postDetail: {
            handler(newVal) {
                if (newVal) {
                    this.post = {
                        id: newVal.id,
                        title: newVal.title,
                        title_en: newVal.title_en,
                        category_id: newVal.category_id,
                        image: "",
                        showImg: baseUrl + newVal.image,
                        short_description: newVal.short_description,
                        short_description_en: newVal.short_description_en,
                        link: newVal.link,
                        status: newVal.status,
                        content: newVal.content,
                        content_en: newVal.content_en,
                    };
                }
            },
            deep: true,
        },
    },
    methods: {
        validationForm() {
            this.$refs.updatePostModal.validate().then((success) => {
                if (success) {
                    this.updatePost(this.post);
                }
            });
        },
        async updatePost(postData) {
            await this.$store
                .dispatch("frontend/updatePost", postData)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.code == "00") {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || "success",
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });
                            this.$emit("refetch-data");
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$bvModal.hide("modal-update-post");
                                    this.resetModal();
                                }, "500");
                            });
                            this.$emit("refetch-data");
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        }
                    }
                });
        },
        resetModal() {
            this.post = {
                title: "",
                title_en: "",
                category_id: "",
                image: null,
                short_description: "",
                short_description_en: "",
                link: "",
                status: "",
                content: "",
                content_en: "",
            };
            this.$emit("update:postDetail", null);
        },
        confirm(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.validationForm();
        },
    },
    setup() {
        return {};
    },
};
</script>